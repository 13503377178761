import { lazy, Suspense } from 'react';
import { Route, Routes as BaseRoutes } from "react-router-dom";
import NoPage from './pages/NoPage';

const Home = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./pages/Home")), 100);
  });
});
const Products = lazy(() => import('./pages/Products'));
const Product = lazy(() => import('./pages/Product'));
const Webgl = lazy(() => import('./pages/Webgl'));
const Drawer = lazy(() => import('./components/TemporaryDrawer'));

// route app
const Login = lazy(() => import('./pages/Login'));
const MainMenu = lazy(() => import('./pages/MainMenu'));
const Profil = lazy(() => import('./pages/Profil'));
const StudiKasus = lazy(() => import('./pages/StudiKasus'));
const Kasus = lazy(() => import('./pages/StudiKasus/Kasus'));
const Assesment = lazy(() => import('./pages/Assesment'));

const tokenOrganizer = require('./util/tokenOrganizer')

export default function Routes() {

  let token = tokenOrganizer.getToken();

  if (!token) {
    return <Login setToken={tokenOrganizer.setToken} />;
  }

  return (
    <Suspense fallback={<div className="container">Loading...</div>}>
      <BaseRoutes>
        <Route path="/" element={<MainMenu revokeToken={tokenOrganizer.revokeToken} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/profil" element={<Profil />} />

        <Route path="studi-kasus">
          <Route index element={<StudiKasus />} />
          <Route path=":studiKasus" element={<Kasus />} />
          <Route path="*" element={<NoPage />} />
        </Route>

        <Route path="assesment">
          <Route index element={<Assesment />} />
          <Route path=":studiKasus" element={<Assesment />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </BaseRoutes>
    </Suspense>
  );
}