// token.js
function setToken(userToken) {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    window.location.reload();
}

function revokeToken() {
    sessionStorage.clear();
    window.location.reload();
}

function getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.accessToken
}


module.exports = {
    setToken,
    revokeToken,
    getToken
}
